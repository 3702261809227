body.chatbot-page::-webkit-scrollbar {
  display: none;
}
.widget__wrapper {
  position: fixed;
  right: 20px;
  top: 96px;
  z-index: 121;
  border-radius: 50%;
  display: flex;
  align-items: self-end;
  flex-direction: column;
}

.widget__wrapper.promobar {
  top: 166px;
}

.widget__button-wrapper {
  display: none;
  position: relative;
}

.widget__button-open {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  background-color: #3971C1;  
  transition: width 500ms, border-radius 500ms;
  display: none;
}

.widget__button-open .button-open__text {
  opacity: 0;
  height: 0;
  font-family: Lato;
  font-size: 0;
  font-weight: 700;
  line-height: 24px;
  font-size: 14px;
  transition: height 200ms, width 200ms;
  position: relative;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate3d(0, -50%, 0);
}
.widget__button-open:hover .button-open__text {
  z-index: 10;
  animation: botButtonTextAnimation 200ms forwards;
}

.widget__button-open .button-open__img {
  border-radius: 50%;
  height: 45px;
  z-index: 1;
}

.widget__button-open.show-button {
  display: flex;
  width: 45px;
  height: auto;
  opacity: 1;
}

.widget__button-open:hover {
  width: 145px;
  height: 45px;
  border-radius: 100px;
  background-color: #3971C1;
  box-shadow: 0px 11px 14px -5px rgba(57, 113, 193, 0.5);
}

.widget__button-open.hovered {
  width: 198px;
  height: 45px;
  border-radius: 100px;
  background-color: #3971C1;
  box-shadow: 0px 11px 14px -5px rgba(57, 113, 193, 0.5);
}

.widget__button-open.hovered .button-open__text {
  left: 10%;
  width: auto;
  height: auto;
  opacity: 1;
  margin-right: 15px;
  font-size: 14px;
}

.widget__button-open.home-page {
  position: relative;
}
.new-tag-chatbot {
  position: absolute;
  background-color: #0DC69F;
  top: -6px;
  left: 15px;
  z-index: 10;
}
.widget__button-open:not(:hover) .new-tag-chatbot {
  width: 7px;
  height: 7px;
  top: 0px;
  left: 7px;
  border-radius: 50%;
  overflow: hidden;
  color: #0DC69F;
}


.widget__wrapper.expanded {
  position: static;
  height: calc(100vh - 75px);
  z-index: 0;
  border-radius: 0;
  background-color: #fff; 
}
.widget__wrapper.blured--chatbot-page {
  filter: blur(10px) brightness(.5);
}
.widget__wrapper.expanded.desktopPromo {
  height: calc(100vh - 133px);
}
.widget__wrapper.expanded.mobilePromobar {
  height: calc(100vh - 150px);
}

.widget__padding-bottom.expanded {
  height: 65px;
} 
.widget__padding-bottom.expanded.cookies {
  height: 125px;
}

.widget__wrapper.expanded .react-chatbot-kit-chat-container {
  width: 100%;
  box-shadow: none;
}
.widget__wrapper.expanded .react-chatbot-kit-chat-inner-container {
  height: 100%;
}

.widget__header-icons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 11px;
  right: -80px;
  z-index: 21;
  color: #3971C1;
  font-weight: 400;
  font-size: 0;
  opacity: 0;
  transition: all 800ms;
}

.widget__header-icons.show-header-icons {
  right: 13px;
  opacity: 1;
  font-size: 20px;
}

.widget__header-icons.show-header-icons .widget__header-icon-times {
  margin-left: 10px;
  height: 19.5px; 
  padding: 5px 5px 5px 0;
}

.widget__header-icons.show-header-icons .widget__header-icon-expand {
  margin-left: 10px;
  height: 15px;
  padding: 5px 0px 5px 5px;
}

.widget__header-icons .widget__header-icon-times,
.widget__header-icons .widget__header-icon-expand {
  cursor: pointer;
  width: auto;
  transition: all 800ms;
}

.widget__header-icons.show-header-icons .widget__header-icon-times,
.widget__header-icons.show-header-icons .widget__header-icon-expand {
  width: 21px; 
} 

.widget__header-icons.expanded .widget__header-icon-times {
  display: none;
}

.widget__header-icons.expanded {
  position: fixed;
  border-radius: 50%;
  border: 1px solid #3971C1;
  width: 35px;
  height: 35px;
  top: 145px;
  right: 35px;
}

.widget__header-icons.expanded.promobar {
  top: 165px;
}

.widget__header-icons.expanded:hover {
  background: #3971c1;
}

.widget__header-icons.expanded:hover svg {
  color: #fff;
}
.widget__header-icons.expanded .widget__header-icon-expand {
  margin: auto;
  padding: 0;
}

.widget__footer-icons {
  position: absolute;
  bottom: 5px;
  right: -80px;
  z-index: 1;
  transition: all 800ms;
}

.widget__footer-icons.show {
  right: 0;
}

.button-show-more {
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 25px;
  padding: 8px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(57, 113, 193, 0.22);
  border-radius: 10px;
  cursor: pointer;
  color: #3971C1;
  line-height: normal;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
}

.button-show-more:hover {
  background-color: #3971C1;
  color: #fff;
}

.button-show-more span {
  margin-right: 5px;
} 

.react-chatbot-kit-chat-header-custom {
  position: absolute;
  height: auto;
  width: 100%;
  min-height: 55px;
  background-color: #fff;
  padding: 0 14px 0 13px;
  border-radius: 10px 10px 0px 0px;
  z-index: 11;
} 
.react-chatbot-kit-chat-header {
  display: none;
}

.react-chatbot-kit-chat-header-custom .header__tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: -5px;
}
.react-chatbot-kit-chat-header-custom .header__tags span:first-child {
  margin-right: 10px;
}
.react-chatbot-kit-chat-header-custom .header__title {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #3971C1;
}
.widget__wrapper.expanded .react-chatbot-kit-chat-header-custom {
  display: none;
}

.react-chatbot-kit-chatbot-avatar {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
}
.react-chatbot-kit-chatbot-avatar svg {
  width: 37px;
  height: 37px;
}

.react-chatbot-kit-chat-container {
  width: 400px;
  box-shadow: 0px 10px 32px 8px rgba(57, 113, 193, 0.144067);
  border-radius: 10px;
}

.react-chatbot-kit-chat-inner-container {
  height: 556px;
  overflow: auto;
}
.react-chatbot-kit-chat-inner-container::-webkit-scrollbar {
  display: none;
}

.react-chatbot-kit-chat-input-container {
  overflow: auto;
  padding: 0 13px 0px 10px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  z-index: 899;
}
.widget__wrapper.expanded .react-chatbot-kit-chat-input-container {
  position: fixed;
}
.widget__wrapper.expanded .react-chatbot-kit-chat-input-container {
  box-shadow: 0px -1px 12px rgba(57, 113, 193, 0.144067);
  padding: 0;
  border-radius: 0;
}
.widget__wrapper.expanded.cookies .react-chatbot-kit-chat-input-container {
  bottom: 60px;
}


.react-chatbot-kit-chat-message-container {
  overflow: visible;
  height: auto;
  max-width: 900px;
  margin: 0 auto;
  padding: 85px 10px 60px 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  position: relative;
}
.widget__wrapper.expanded .react-chatbot-kit-chat-message-container {
  padding: 30px 10px 0 10px;
  position: relative;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  display: none;
}
.react-chatbot-kit-chat-message-container > div:first-child {
  margin-top: auto;
}
.react-chatbot-kit-chat-message-container > div {
  flex: 0 0 auto;
}


.react-chatbot-kit-chat-input-form {
  margin: 15px 0 15px;
  justify-content: space-between;
}
.react-chatbot-kit-chat-input {
  width: 100%;
  max-width: 325px;
  height: 35px;
  background: #FFFFFF;
  border: 1px solid #A5BDE3;
  border-radius: 10px;
}
.react-chatbot-kit-chat-input::placeholder {
  color: #A5BDE3;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.react-chatbot-kit-chat-btn-send {
  width: auto;
  padding: 7px;
  margin-left: 13px;
  border-radius: 8.25px;
  background-color: #fff;
}
.react-chatbot-kit-chat-btn-send.disabled-on-loading,
.loading-page .react-chatbot-kit-chat-btn-send {
  pointer-events: none;
  opacity: .5;
}
.react-chatbot-kit-chat-btn-send svg {
  fill: #A5BDE3;
  width: 21px;
  height: 19.5px;
}
.react-chatbot-kit-chat-btn-send:active {
  background-color: #3971C1 !important;
  box-shadow: 0px 4.5px 9px rgba(57, 113, 193, 0.144067);
}
.react-chatbot-kit-chat-btn-send:active svg {
  fill: #fff;
}


.widget__wrapper.expanded .react-chatbot-kit-chat-input-form {
  max-width: 691px;
  margin: 15px auto 15px;
}
.widget__wrapper.expanded .react-chatbot-kit-chat-input {
  max-width: 642px;
}

.react-chatbot-kit-chat-bot-message-container {
  margin: 20px 0 10px 0;
  position: relative;
}

.react-chatbot-kit-user-chat-message-container {
  margin: 20px 0 10px 0;
  position: relative;
}
.react-chatbot-kit-user-chat-message {
  margin: 0 0 auto;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0 0 auto;
  background-color: #3971C1;
  color: #fff;
  border: 1px solid rgba(57, 113, 193, 0.1);
  border-radius: 10px;
}
.react-chatbot-kit-user-chat-message-arrow {
  display: none;
}
.react-chatbot-kit-chat-bot-user-avatar {
  flex: 0 0 auto;
  margin-left: 10px;
}
.react-chatbot-kit-chat-bot-user-avatar img,
.react-chatbot-kit-chat-bot-user-avatar svg {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  overflow: hidden;
  fill: #3971C1;
}
.react-chatbot-kit-user-chat-message {
  max-width: calc(100% - 94px);
  white-space: normal;
  overflow-wrap: break-word;
}
.react-chatbot-kit-chat-bot-message {
  width: auto;
  max-width: 78%;
  margin: 0 0 auto;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0 0 auto;
  background-color: #F9FAFD;
  color: #292929;
  border: 1px solid rgba(57, 113, 193, 0.1);
  border-radius: 10px;
  overflow: hidden;
  white-space: break-spaces;
}
.widget__wrapper.expanded .react-chatbot-kit-chat-bot-message {
  max-width: calc(100% - 94px);
}
.react-chatbot-kit-chat-bot-message-arrow {
  display: none;
}

.react-chatbot-kit-user-chat-message-time,
.react-chatbot-kit-chat-bot-message-time,
.react-chatbot-kit-chat-bot-message-date,
.react-chatbot-kit-user-message-date {
  display: flex;
  align-items: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0.8;
  color: rgba(102, 102, 102, 0.75);
}
.react-chatbot-kit-user-chat-message-time {
  margin-right: 10px;
}
.react-chatbot-kit-chat-bot-message-time {
  margin-left: 10px;
}
.react-chatbot-kit-chat-bot-message-date,
.react-chatbot-kit-user-message-date {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.react-chatbot-kit-chat-bot-message-date.hide,
.react-chatbot-kit-user-message-date.hide {
  display: none;
}
.react-chatbot-kit__widget__wrapper {
  display: flex;
  position: relative;
  width: 100%;
  margin: 10px auto;
}
.react-chatbot-kit__widget__wrapper:not(.watchlists, .number-data-tile) > div:first-child {
  max-width: 100%;
  width: 100%;
}
.react-chatbot-kit__widget__wrapper::-webkit-scrollbar {
  display: none;
}

.react-chatbot-kit__widget__wrapper .topics__item:not(.sentiment-list) {
  padding: 10px 20px;
  font-size: 12px;
  color: #666666;
  line-height: 15px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 2px 10px rgba(57, 113, 193, 0.22);
}
.react-chatbot-kit__widget__wrapper .topics__item:not(.sentiment-list):hover {
  color: #fff;
  background-color: #3971C1;
  cursor: pointer;
}

.react-chatbot-kit__widget__wrapper .query__item {
  padding: 10px 20px;
  font-size: 12px;
  color: #666666;
  line-height: 8px;
  text-align: center;
  color: #3971C1;
  border-radius: 10px;
  border: 1px solid #3971C1;
  box-shadow: 0px 2px 10px rgba(57, 113, 193, 0.22);
}
.react-chatbot-kit__widget__wrapper .query__item:hover {
  color: #fff;
  background-color: #3971C1;
  cursor: pointer;
}
.react-chatbot-kit__widget__wrapper.charts {
  display: block;
  overflow: visible;
}

.react-chatbot-kit__widget__wrapper.watchlists {
  flex-direction: column;
  max-width: 635px;
}

.react-chatbot-kit__widget__wrapper.yes-no-button span {
  padding: 10px 15px;
  margin-right: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 13px -7px rgba(57, 113, 193, 0.3);
  color: #3971C1;
  border: 1px solid #3971c1;
  font-size: 12px;
  font-family: Lato; 
  line-height: 8px;
  cursor: pointer;
}

.react-chatbot-kit__widget__wrapper.yes-no-button span:hover {
  background-color: #3971C1;
  color: #fff;
}
.react-chatbot-kit__widget__wrapper.yes-no-button.disabled span {
  opacity: .5;
  pointer-events: none;
}
.react-chatbot-kit__widget__wrapper.yes-no-button.disabled span.clicked {
  background-color: #3971C1;
  color: #fff;
  opacity: 1;
}

.react-chatbot-kit__widget__wrapper.number-data-tile .item {
  height: fit-content;
  border-radius: 10px;
  border: 0.9px solid #EDF3FA;
  background: #FFF;
  box-shadow: 0px 4.049999713897705px 8.09999942779541px 1.3499999046325684px rgba(57, 113, 193, 0.14);
  color: #292929;
  font-size: 16px;
  font-family: Lato;
  font-weight: 700;
  padding: 8px 14px;
}

.react-chatbot-kit__widget__wrapper.filings-cards {
  flex-direction: column;
}
.react-chatbot-kit__widget__wrapper.filings-cards .filing-row, 
.react-chatbot-kit__widget__wrapper.filings-cards .card-row{
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 10px;
}
.react-chatbot-kit__widget__wrapper.filings-cards .filing-row.separating-line:after, 
.react-chatbot-kit__widget__wrapper.filings-cards .card-row.separating-line:after {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #E0E0E0;
  width: 100%;
  content: '';
  z-index: 122;
}

.react-chatbot-kit__widget__wrapper.show-more {
  position: relative;
  padding-bottom: 40px;
}
.react-chatbot-kit__widget__wrapper.key_headlines {
  border-radius: 10px;
  border: 2px solid #EFF4FA;
  background: #FFF;
  box-shadow: 0px 6px 13px -7px rgba(57, 113, 193, 0.30);
}

.react-chatbot-kit__widget__wrapper.number-data-tile {
  gap: 10px;
  flex-wrap: wrap;
  padding: 0;
}

.react-chatbot-kit__widget__wrapper.sentiments {
  flex-direction: column;
  overflow-x: initial;
  min-height: 525px;
}

.react-chatbot-kit__widget__wrapper.sentiments.preloader_showed {
  box-shadow: 0px 4.0499997139px 8.0999994278px 1.3499999046px rgba(57, 113, 193, 0.14);
  border-radius: 10px;
}

.react-chatbot-kit__widget__wrapper.chatbot-icon img.chatbot {
  width: 100%;
  max-width: 120px;
  height: auto;
}

.react-chatbot-kit__widget-stories__item,
.react-chatbot-kit__widget-options__item {
  width: 100%;
  max-width: 100px;
  height: 115px;
  margin: 0;
  padding: 10px 7px 15px 7px;
  background: #F9FAFD;
  border: 1px solid rgba(57, 113, 193, 0.1);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.react-chatbot-kit__widget-stories__item {
  width: 100px;
}
.react-chatbot-kit__widget-options__item.middle {
  max-width: 130px;
}
.react-chatbot-kit__widget-options__item.bigger {
  max-width: 150px;
}
.react-chatbot-kit__widget-options__item.biggest {
  max-width: 180px;
}
.react-chatbot-kit__widget-options__item:hover {
  box-shadow: 0px 6px 12px rgba(57, 113, 193, 0.144067);
  background: #FFFFFF;
  border: 1px solid #A5BDE3;
}

.chatbot-widget__scrollable {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
}
.chatbot-widget__scrollable.stories {
  padding: 10px 13px 15px 13px;
}


.sentiment-list {
  display: flex;
  gap: 5px;
}
.sentiment-list>div:first-child {
  max-width: 100%;
  width: 100%;
}
.react-chatbot-kit__widget-stories__item .chatbot-widget__scrollable > div:first-child,
.react-chatbot-kit__widget-options__item .chatbot-widget__scrollable > div:first-child {
  margin-left: 35px;
}
.react-chatbot-kit__widget-stories__item .chatbot-widget__scrollable > div:last-child,
.react-chatbot-kit__widget-options__item .chatbot-widget__scrollable > div:last-child {
  margin-right: 35px;
}



.react-chatbot-kit__widget-options__item-image {
  height: 55%;
  width: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.react-chatbot-kit__widget-options__item-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.react-chatbot-kit__widget-options__item h4 {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #292929;
  line-height: 20px;
}
.chatbot-loader__wrapper {
  display: flex;
}
.chatbot-loader-container {
  width: auto;
  max-width: fit-content;
  justify-content: flex-start;
  background-color: #F9FAFD;
  border: 1px solid rgba(57, 113, 193, 0.1);
  border-radius: 10px;
  padding: 5px 10px;
}

.chatbot-date {
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 53px;
  transform: translateX(-51%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0.8;
  color: rgba(102, 102, 102, 0.75);
  margin: 5px 0 10px;
  padding: 8px 17px;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 0px 7px 12px rgba(57, 113, 193, 0.144067);
}
.chatbot-date.expanded {
  position: fixed;
  top: 80px;
  transform: translateX(-50%);
}

.widget__wrapper.expanded .react-chatbot-kit-chat-message-container > div.react-chatbot-kit__widget__wrapper.show-more {
  padding-bottom: 40px;
}
.react-chatbot-kit-chat-message-container > div.react-chatbot-kit__widget__wrapper.stories {
  margin: 0;
}

.widget__content {
  width: 0;
  height: 0;
  opacity: 0;
  transition: width 800ms;
} 

.widget__content.opened-widget-view {
  width: 400px;
  height: auto;
  opacity: 1;
} 
.widget__content.opened-widget-view.demo_video-mode {
  display: flex;
  flex-direction: column;
  height: 605px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 10px 32px 8px rgba(57, 113, 193, 0.14);
} 

.widget__outer-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.widget__outer-wrapper.expanded {
  overflow: hidden;
}
.widget__inner-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column-reverse;
}
.widget__inner-wrapper.expanded {
  overflow: scroll;
}
.widget__inner-wrapper::-webkit-scrollbar {
  display: none;
}

.widget__content.opened-page-view {
  width: 100%;
  opacity: 1;
  height: 100%;
  transition: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.widget__preloader-wrapper {
  position: fixed;
  bottom: 65px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 898;
  background-color: #fff;
  box-shadow: 0px -1px 12px rgba(57, 113, 193, 0.144067);
}
.widget__preloader-wrapper.cookies {
  bottom: 125px;
}
.widget__preloader-wrapper.finprompt-page {
  bottom: initial;
  position: absolute;
  display: flex;
  align-items: flex-end;
}
.widget__preloader-wrapper.widget__preloader-wrapper--on-scrolling-content {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 55px;
  box-shadow: none;
  min-width: 400px;
  max-height: 440px;
}

.widget__preloader-wrapper.widget__preloader-wrapper--on-scrolling-content {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 55px;
  box-shadow: none;
  min-width: 400px;
  max-height: 440px;
}

.react-chatbot-kit__widget__chart {
  min-height: 535px;
}
.react-chatbot-kit__widget__chart:nth-child(even) {
  margin-bottom: 20px;
}
.react-chatbot-kit__widget__transaction {
  margin-bottom: 20px;
}

.react-chatbot-kit__widget__wrapper.generic-component canvas {
  width: 100% !important;
}
.react-chatbot-kit__widget__wrapper.generic-component {
  flex-direction: column;
}
.react-chatbot-kit__widget__wrapper.generic-component.full-height canvas {
  height: auto !important;
}

.error-boundary__wrapper {
  margin: 10px auto 20px;
}
.error-boundary__content {
  border: 1px solid rgba(57, 113, 193, 0.1);
  background-color: #F9FAFD;
  border-radius: 10px;
  padding: 10px;
}

@media(max-height: 700px) and (min-width: 1025px) {
  .react-chatbot-kit-chat-inner-container {
    height: calc(100vh - 150px);
  }
  .widget__content.opened-widget-view.demo_video-mode {
    height: calc(100vh - 100px);
  }
}
.demo_video-btn {
  position: fixed;
  top: 90px;
  right: 20px;
  border-radius: 10px;
  background: var(--global-white, #FFF);
  box-shadow: 0px -1px 12px 0px rgba(57, 113, 193, 0.14);
  padding: 10px 20px 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.demo_video-btn svg {
  color: #3971C1;
  width: 18px;
  height: auto;
}
.demo_video-btn > span {
  display: inline-block;
  margin-left: 4px;
  color: #3971C1;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.demo_video-btn:hover {
  background: #3971C1;
  box-shadow: 0px -1px 12px 0px rgba(57, 113, 193, 0.50);
}
.demo_video-btn:hover svg,
.demo_video-btn:hover > span {
  color: #fff;
}
.demo_video-btn.demo_video-mode--hidden {
  display: none;
}

@media(min-width: 768px) {
.widget__wrapper.expanded .react-chatbot-kit__widget__wrapper {
  max-width: calc(100% - 70px);
}
.widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.charts,
.widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.filings-cards,
.widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.watchlists,
.widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.yes-no-button,
.widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.generic-component {
  max-width: calc(100% - 94px);
}
.widget__wrapper.expanded .react-chatbot-kit-chat-message-container>div.react-chatbot-kit__widget__wrapper.stories {
  max-width: calc(100% - 70px);
  margin: auto;
  padding: 0 0 40px 0;
}

.widget__wrapper.expanded .react-chatbot-kit-chat-message-container>div.react-chatbot-kit__widget__wrapper.stories .widget__footer-icons.show {
  right: 13px;
}
}

@media(min-width: 1025px) {
  .widget__button-wrapper {
    display: block;
    position: relative;
    right: 0;
    bottom: 0;
  }
  .widget__button-wrapper.chatbotbanner {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .widget__button-wrapper.chatbotbanner .widget__button-open {
    pointer-events: none;
  }
  .expanded .widget__button-wrapper {
    display: none;
  }
  .chatbot-date.expanded {
    top: 80px;
  }
}


@media(min-width: 2800px) {
  .widget__padding-bottom.expanded {
    height: 100px;
  }
  .widget__padding-bottom.expanded.cookies {
    height: 160px;
  }

  .widget__button-wrapper.chatbotbanner {
    right: 30px;
    bottom: 30px;
  }
  .widget__button-open:not(:hover) .new-tag-chatbot {
    width: 20px;
    height: 20px;
  }

  .react-chatbot-kit-chat-bot-message-container,
  .react-chatbot-kit-user-chat-message-container {
    margin: 40px 0 20px 0;
  }
  .widget__button-open.show-button {
    min-width: 90px;
  }
  .widget__button-open.hovered {
    height: 90px;
    width: 400px;
  }
  .widget__button-open:hover {
    width: calc(138px * 2);
    height: 90px;
  }
  .widget__button-open .button-open__img {
    height: 90px;
  }
  .widget__button-open.expanded .button-open__img {
    height: 0px;
  }
  .widget__button-open:hover .button-open__text {
    font-size: 28px;
  }
  .widget__button-open.hovered .button-open__text {
    font-size: 28px;
  }

  .widget__content.opened-widget-view {
    width: 640px;
  }
  .widget__content.opened-page-view {
    width: 100%;
  }

  .widget__header-icons.show-header-icons {
    font-size: 40px;
    top: 30px;
  }
  .widget__header-icons.expanded.show-header-icons {
    top: 165px;
  }
  .widget__header-icons.expanded {
    font-size: 40px;
    width: 70px;
    height: 70px;
  }
  .widget__header-icons.show-header-icons .widget__header-icon-times,
  .widget__header-icons.show-header-icons .widget__header-icon-expand {
    width: 40px;
    font-size: 40px;
  }
  .widget__header-icons.show-header-icons .widget__header-icon-expand {
    height: 30px;
  }
  .widget__header-icons.show-header-icons .widget__header-icon-times {
    height: 40px;
    margin-left: 20px;
  }

  .react-chatbot-kit-chat-container {
    width: 640px;
  }
  .react-chatbot-kit-chat-inner-container {
    height: 1800px;
  }

  .react-chatbot-kit-chat-header-custom {
    min-height: 120px;
  }
  .react-chatbot-kit-chat-header-custom .header__tags {
    top: -10px;
  }
  .react-chatbot-kit-chat-header-custom .header__title {
    font-size: 32px;
    line-height: 48px;
  }
  .react-chatbot-kit-chat-header-custom .header__tags span:first-child {
    margin-right: 25px;
  }
 
  .widget__footer-icons.show {
    right: 30px;
  }
  .button-show-more {
    max-width: 200px;
    max-height: 60px;
    padding: 16px 20px;
    font-size: 24px;
    border-radius: 20px;
  }

  .react-chatbot-kit-chat-message-container {
    max-width: 2000px;
    padding: 165px 10px 110px;
  }
  .widget__wrapper.expanded .react-chatbot-kit-chat-message-container {
    padding: 65px 20px 0;
  }
  .react-chatbot-kit-chatbot-avatar {
    margin-right: 20px;
  }
  .react-chatbot-kit-chatbot-avatar svg {
    width: 46px;
    height: 46px;
    font-size: 40px;
  }
  .react-chatbot-kit-chat-bot-message {
    font-weight: 400;
    font-size: 26px;
    line-height: 34px;
    width: auto;
    padding: 10px 20px;
  }
  .react-chatbot-kit-user-chat-message {
    font-size: 26px;
    line-height: 34px;
  }
  .react-chatbot-kit-chat-bot-user-avatar {
    margin-left: 20px;
  }
  .react-chatbot-kit-chat-bot-user-avatar img,
  .react-chatbot-kit-chat-bot-user-avatar svg {
    width: 45px;
    height: 45px;
  }

  .react-chatbot-kit-chat-input-form {
    margin: 25px 0 25px;
  }
  .widget__wrapper.expanded .react-chatbot-kit-chat-input-form {
    max-width: 1850px;
  }
  .react-chatbot-kit-chat-input-container {
    padding: 0 26px 0 20px;
  }
  .react-chatbot-kit-chat-input {
    max-width: 500px;
    height: 70px;
    border: 2px solid #A5BDE3;
    border-radius: 20px;
    font-size: 24px;
  }
  .widget__wrapper.expanded .react-chatbot-kit-chat-input {
    max-width: 100%;
  }
  .react-chatbot-kit-chat-input::placeholder {
    font-size: 24px;
  }
  .react-chatbot-kit-chat-btn-send {
    padding: 14px;
    margin-left: 26px;
    border-radius: 20px;
  }
  .react-chatbot-kit-chat-btn-send svg {
    width: 42px;
    height: 39px;
  }

  .react-chatbot-kit__widget-stories__item,
  .react-chatbot-kit__widget-options__item {
    max-width: 200px;
    height: 230px;
    padding: 10px;
    border-radius: 20px;
  }
  .react-chatbot-kit__widget-options__item.middle {
    max-width: 225px;
  }
  .react-chatbot-kit__widget-options__item.bigger {
    max-width: 250px;
  }
  .react-chatbot-kit__widget-options__item.biggest {
    max-width: 290px;
  }
  .react-chatbot-kit__widget-options__item h4 {
    font-size: 24px;
    line-height: 34px;
  }
  .react-chatbot-kit-user-chat-message-time,
  .react-chatbot-kit-chat-bot-message-time,
  .react-chatbot-kit-chat-bot-message-date,
  .react-chatbot-kit-user-message-date {
    font-size: 20px;
  }
  .react-chatbot-kit-chat-bot-message-date,
  .react-chatbot-kit-user-message-date {
    top: -35px;
  }
  .chatbot-date {
    top: 120px;
    font-size: 20px;
  }
  .chatbot-date.expanded {
    top: 85px;
    padding: 12px 35px;
  }
  .widget__wrapper.promobar .chatbot-date.expanded {
    top: 150px;
  }
  .react-chatbot-kit__widget__wrapper {
    margin: 20px auto;
  }
  .widget__wrapper.expanded .react-chatbot-kit__widget__wrapper,
  .widget__wrapper.expanded .react-chatbot-kit-chat-bot-message {
    max-width: calc(100% - 130px);
  }
  .widget__wrapper.expanded .react-chatbot-kit__widget__wrapper {
    max-width: calc(100% - 110px);
  }

  .widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.charts,
  .widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.filings-cards,
  .widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.watchlists,
  .widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.yes-no-button,
  .widget__wrapper.expanded .react-chatbot-kit__widget__wrapper.generic-component {
    max-width: calc(100% - 130px);
  }

  .widget__wrapper.expanded .react-chatbot-kit-chat-message-container>div.react-chatbot-kit__widget__wrapper.stories {
    max-width: calc(100% - 100px);
  }
  .react-chatbot-kit-user-chat-message {
    max-width: calc(100% - 130px);
    padding: 10px 20px;
  }
  .react-chatbot-kit__widget__wrapper.show-more,
  .widget__wrapper.expanded .react-chatbot-kit-chat-message-container
  > div.react-chatbot-kit__widget__wrapper.show-more {
    padding-bottom: 70px; 
  }
  .widget__wrapper .react-chatbot-kit-chat-message-container
  > div.react-chatbot-kit__widget__wrapper.stories {
    padding-bottom: 70px;
  }

  .react-chatbot-kit__widget-options__item-image img {
    width: 100%;
  }
  .widget__preloader-wrapper {
    bottom: 100px;
  }
  .widget__preloader-wrapper.cookies {
    bottom: 160px;
  }
  .widget__preloader-wrapper.finprompt-page {
    bottom: initial;
  }
  .widget__preloader-wrapper.widget__preloader-wrapper--on-scrolling-content {
    top: 120px;
    min-width: 640px;
    max-height: 1560px;
  }
  .react-chatbot-kit__widget__wrapper.chatbot-icon img.chatbot {
    max-width: 180px;
  }
  .chatbot-widget__scrollable.stories {
    padding: 15px 20px 30px 20px;
  }
  .chatbot-widget__scrollable.sentiments-list>div:first-child .sentiment-tags-content-no-data {
    font-size: 28px;
  }

  .react-chatbot-kit__widget__wrapper.filings-cards .filing-row,
  .react-chatbot-kit__widget__wrapper.filings-cards .card-row {
    margin-bottom: 20px;
    padding-bottom: 70px;
  }
  .react-chatbot-kit__widget__wrapper.filings-cards .filing-row.separating-line:after,
  .react-chatbot-kit__widget__wrapper.filings-cards .card-row.separating-line:after {
    bottom: 35px;
    height: 2px;
  }
}

@media(max-width: 1024px) {
  .widget__header-icons.expanded {
    display: none;
  }
  .react-chatbot-kit-chat-input,
  .react-chatbot-kit-chat-input:focus {
    font-size: 16px;
  }
  .widget__button-wrapper {
    display: none;
  } 
  .promobar .chatbot-date.expanded {
    top: 150px;
  }
  .demo_video-btn {
    position: absolute;
    top: 10px;
  }
  .widget__content.opened-page-view {
    position: relative;
  }
}


@media(max-width: 850px) {
  .widget__wrapper.expanded .react-chatbot-kit-chat-input-form {
    margin-left: 20px;
  }
}

@media(max-width: 767px) {
  .widget__wrapper.expanded.mobilePromobar {
    height: calc(100vh - 75px);
  }
  .widget__padding-bottom.expanded.cookies {
    height: 105px;
  }
  .widget__wrapper.expanded {
    top: 0 !important;
  }
  .widget__header-icons.expanded {
    display: none;
  }
  .widget__wrapper.expanded .react-chatbot-kit-chat-input-form {
    width: 100%;
    margin: 15px 60px 15px 10px;
  }
  .react-chatbot-kit-chat-btn-send {
    margin-left: 6px;
  }
  .widget__wrapper.expanded.cookies .react-chatbot-kit-chat-input-container {
    bottom: 40px;
  }
  .widget__footer-icons.expanded {
    right: 10px;
  }
  .chatbot-date.expanded {
    top: 80px;
  }
  .promobar .chatbot-date.expanded {
    top: 210px;
  }
  .widget__content.opened-page-view.chatbot-page {
    padding-top: 0;
  }
}

@media(max-width: 480px) {
  .widget__wrapper.expanded .react-chatbot-kit-chat-message-container>div.react-chatbot-kit__widget__wrapper.charts {
    padding: 0 10px;
  }
}

@media (hover: none) {
  .widget__button-wrapper {
    display: none;
  }
  .react-chatbot-kit-chat-btn-send:hover {
    background-color: transparent;
  }
}

@media (hover: hover) {
  .react-chatbot-kit-chat-btn-send:hover {
    background-color: #3971C1;
    box-shadow: 0px 4.5px 9px rgba(57, 113, 193, 0.144067);
  }
  .react-chatbot-kit-chat-btn-send:hover svg {
    fill: #fff;
  }
}



@keyframes botButtonTextAnimation {
  75% {
    transform: translate3d(50%, -50%, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, -50%, 0);
    opacity: 1;
    width: auto;
    height: auto;
  }
}
