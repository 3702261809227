.TopicItem {
  padding: 10px 20px;
}

.TopicItem.expanded {
  background-color: #ffffff;
}

.TopicItem.basic .TopicItem__info>*,
.TopicItem.basic .TopicItem__titles>.TopicItem__titles--sub-head {
  display: none;
}

.TopicItem.basic:hover .TopicItem__info>.TopicItem__info--icon-dots {
  display: block;
}

.TopicItem.basic .TopicItem__titles {
  min-width: 96%;
}

.TopicItem__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TopicItem__expand-div {
  display: none;
  padding-top: 20px;
}

.TopicItem__expand-div--item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.TopicItem__expand-div--item:first-child {
  padding-bottom: 12px;
}

.TopicItem__expand-div--item>.mdi-icon {
  margin-left: -3px;
  padding-left: 1px;
}

.TopicItem__expand-div--item:hover>span {
  color: #3971c1;
}

.TopicItem__expand-div--item:hover>.hover-img {
  display: block;
}

.TopicItem__expand-div--item:hover>.default-img {
  display: none;
}

.TopicItem__expand-div--item>span {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: right;
  color: #666666;
  padding-left: 11px;
}

.TopicItem__expand-div--item>img {
  width: 16px;
}

.TopicItem__expand-div--item>.hover-img {
  display: none;
}

.TopicItem.expanded>.TopicItem__expand-div {
  display: block;
}

.TopicItem.expanded>.TopicItem__expand-div svg {
  color: #6b6b6b;
  height: 15px;
}

.TopicItem__titles {
  flex: 1;
  width: 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TopicItem.chatbot .TopicItem__titles {
  margin-right: 10px;
  max-width: 100%;
  flex: 1 1 auto;
}


.TopicItem__titles--head {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  color: #292929;
  overflow: hidden;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.TopicItem.chatbot .TopicItem__titles--head {
  min-width: fit-content;
}

.TopicItem.basic .TopicItem__titles--head {
  width: 98%;
}

.TopicItem__titles--sub-head {
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 10px;
  padding-top: 5px;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  text-transform: uppercase;
}

.TopicItem__info {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.TopicItem__info--icon-dots {
  color: #292929;
  height: 13px;
  line-height: 15px;
  cursor: pointer;
}

.TopicItem__info--icon-arrow {
  color: #292929;
  line-height: 15px;
  height: 13px;
}

.TopicItem__info--arrow-value {
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: normal;
  text-align: center;
  color: #292929;
  padding-left: 5px;
}

.TopicItem__info--bar {
  flex: auto;
  width: 59px;
  height: 15px;
  border-radius: 3px;
  margin: 0px 10px;
  background-color: #c1cfe3;
}

.TopicItem__info--bar>.bar-value {
  height: 15px;
  display: block;
  border-radius: 3px;
  background: url('../../../../assets/trending/icons/topic_bar_bg.png') 100% 100% repeat;
  background-color: #699adf;
}

.TopicItem.chatbot {
  border: 2px solid #EFF4FA;
  border-radius: 10px;
  margin: 0 2.5px;
  height: fit-content;
}
.TopicItem.chatbot:hover {
  box-shadow: 0px 6px 13px -7px rgba(57, 113, 193, 0.298651);
  background: #F9FAFD;
  cursor: pointer;
}

@media (min-width: 769px) {
  .TopicItem {
    margin: 0 -20px;
  }
  .TopicItem:hover {
    
    background-color: #ffffff;
  }
}
